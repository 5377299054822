import React from "react"
import { useParams } from 'react-router-dom'
import { useNavigate } from "react-router-dom";

// firebase SDK
import firebase from 'firebase/compat/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import { CardHeader, Card, CardBody, CardTitle, CardSubtitle, CardLink, CloseButton } from "reactstrap";

import Likes from "./Likes";
import CommentSection from "./CommentSection";
import DeleteModal from "./DeleteModal";

const messageConverter = {
    toFirestore(message) {
        return {
            ...message
        };
    },
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options);
        return {
            ...data,
            id: snapshot.id
        };
    }
};

export default function EachPost(props) {

    const [post, setPost] = React.useState({});
    const { postid } = useParams();


    const nav = useNavigate();

    const firestore = firebase.firestore();
    const likesRef = firestore.collection("posts").doc(postid).collection("likes").withConverter(messageConverter);
    const [likes] = useCollectionData(likesRef);

    const isImage = ['.gif', '.jpg', '.jpeg', '.png', '.webp'];
    const isVideo = ['.mpg', '.mp2', '.mpeg', '.mpe', '.mpv', '.mp4', '.mov']

    let classString = likes && props.user && likes.some(e => e.uid === props.user.uid) && "btn btn-outline-primary btn-sm rounded-pill active";

    const commentsRef = firestore.collection("posts").doc(postid).collection("comments")
    const [comments] = useCollectionData(commentsRef);

    // console.log(comments && comments)

    const [modalOpen, setModalOpen] = React.useState(false);
    const toggle = () => setModalOpen(!modalOpen);

    React.useEffect(() => {
        console.log("initial fetch " + postid);
        let docRef = firebase.firestore().collection('posts').doc(postid);
        docRef.get().then((doc) => {
            if (doc.exists) {
                // console.log({ id: doc.id, ...doc.data() })
                setPost({ id: doc.id, ...doc.data() });
            }
            // console.log(post)
        })
        // .then(() => console.log(post.title));
    }, [postid]);
    // console.log(props.user)
    // console.log(post)
    // console.log(postid)

    return (
        <>
            {Object.keys(post).length > 0 ?
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h5">
                                        {post.title}
                                        {props.user && props.user.uid === post.uid && <CloseButton style={{ float: "right" }} title="Delete Post?" onClick={toggle} />}
                                    </CardTitle>
                                    <CardSubtitle
                                        className="mb-2 text-muted"
                                        tag="h6"
                                    >
                                        {post.email && post.email.match(/(.*)@gmail.com/)[1]}
                                    </CardSubtitle>
                                </CardHeader>
                                {
                                    post.photoURL &&
                                    <DisplayImageOrVideo />
                                }
                                <CardBody>
                                    <div className="cardtext">
                                        {post.body && post.body.split("¶").map((line, index) => <div key={index}>{line}</div>)}
                                    </div>
                                    {<CardLink>
                                        <Likes
                                            classstring={classString}
                                            likes={likes} user={props.user}
                                            content={post}
                                        />
                                    </CardLink>}
                                    <CardLink>
                                        Comments {comments && `(${Object.keys(comments).length})`}
                                    </CardLink>
                                    {!props.mood &&
                                        <CardLink
                                            href={`/mood/${post.mood}`} className="btn btn-outline-primary btn-sm rounded-pill active">
                                            {post.mood}
                                        </CardLink>
                                    }
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    <CommentSection user={props.user} postid={postid} />
                </div>
                :
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <h4>Post not found ⛓️‍💥</h4>
                        </div>
                    </div>
                </div>
            }
            <DeleteModal modalOpen={modalOpen} toggle={toggle} deleteModalHandler={deleteModalHandler} content={post} />
        </>
    )

    function deleteModalHandler(post) {
        // log data to be delete for now
        // console.log(post);

        const firestore = firebase.firestore();

        if (post.photoURL !== "") {
            console.log("file exists");
            // delete the image if it exists

            let storageRef = firebase.storage().ref();
            let imagesRef = storageRef.child("/" + post.photoURL.match(/(.*\/o\/)(.*)(\?.*)/)[2].replaceAll('%20', " "));

            // Delete the file
            imagesRef.delete().then(() => {
                // File deleted successfully
                console.log("image deletion")
            }).then(() => {
                // deletes likes subcollection
                firestore.collection("posts").doc(post.id).collection("likes").get()
                    .then(res => {
                        res.forEach(element => {
                            element.ref.delete();
                            console.log("like subcollection deletion");
                        });
                    });
            }).then(() => {
                // deletes comments subcollection
                firestore.collection("posts").doc(post.id).collection("comments").get()
                    .then(res => {
                        res.forEach(element => {
                            element.ref.delete();
                            console.log("comment subcollection deletion");
                        });
                    });
            }).then(() => {
                // deletes post document
                firestore.collection("posts").doc(post.id).delete().then(() => {
                    console.log("Post deletion");
                    nav("/");
                });
            }).catch((error) => {
                console.log(error);
            });
        } else {
            console.log("text post");
            // deletes likes subcollection
            firestore.collection("posts").doc(post.id).collection("likes").get()
                .then(res => {
                    res.forEach(element => {
                        element.ref.delete();
                        console.log("Like sub-collection deleted!");
                    });
                }).then(() => {
                    // deletes comments subcollection
                    firestore.collection("posts").doc(post.id).collection("comments").get()
                        .then(res => {
                            res.forEach(element => {
                                element.ref.delete();
                                console.log("comment subcollection deletion");
                            });
                        });
                }).then(() => {
                    firestore.collection("posts").doc(post.id).delete().then(() => {
                        console.log("Post deleted!");
                        nav("/");
                    });
                }).catch((error) => {
                    console.log(error);
                });
        }
    }

    function DisplayImageOrVideo() {
        if (isImage.includes(post.photoURL.match(/(.*)(\..*)(\?.*)/i)[2].toLowerCase())) {
            return (
                <img
                    alt={post.title}
                    src={post.photoURL}
                    width="100%"
                />
            )
        }
        else if (isVideo.includes(post.photoURL.match(/(.*)(\..*)(\?.*)/i)[2].toLowerCase())) {
            return (
                <video
                    width="100%"
                    loop
                    playsInline
                    muted
                    controls
                    preload="metadata"
                >
                    <source src={post.photoURL + "t=0.5"} />
                    Your browser does not support the video tag.
                </video>
            )
        }
    }
}