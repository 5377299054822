import React from 'react';

// firebase SDK
import firebase from 'firebase/compat/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import { Card, ListGroup, ListGroupItem } from "reactstrap";

export default function CommentSection(props) {

    const [formData, setFormData] = React.useState({ comment: '' });

    const firestore = firebase.firestore();
    const commentsRef = firestore.collection("posts").doc(props.postid).collection("comments")
    const [comments] = useCollectionData(commentsRef);

    function changeHandler(e) {
        const { name, value } = e.target;

        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }))
    }

    const submitHandler = async (e) => {
        e.preventDefault();

        const { uid, photoURL, email } = props.user;

        firestore.collection("posts").doc(props.postid).collection("comments").add({
            comment: formData.comment,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            uid: uid,
            userPhotoURL: photoURL,
            email: email
        }).then(() => {
            console.log("Comment successfully added!");
        }).finally(() => {
            firestore.collection("posts").doc(props.postid).update({
                commentsCount: Object.keys(comments).length + 1
            }).then(() => console.log(`Comments set to ${Object.keys(comments).length + 1}`));
            setFormData({ comment: '' });
        });
    }

    return (
        <>
            {props.user &&
                <div className="row mt-3 justify-content-center">
                    <div className="col-md-8">
                        <form onSubmit={submitHandler} >
                            <div className="mb-3">
                                <textarea className="form-control" id="post-body" name="comment" rows="3" placeholder="Post a comment" onChange={changeHandler} value={formData.comment}></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary mt-1">Comment</button>
                        </form>
                    </div>
                </div>
            }

            {comments && <div className="row mt-3 mb-4 justify-content-center">
                <div className="col-md-8">
                    <Card>
                        <ListGroup flush>
                            {comments.map((comment, index) =>
                                <ListGroupItem key={index}>
                                    <div className="blockquote">{comment.comment}</div>
                                    <div className="blockquote-footer">{comment.email && comment.email.match(/(.*)@gmail.com/)[1]}</div>
                                </ListGroupItem>)}
                        </ListGroup>
                    </Card>
                </div>
            </div>
            }
        </>
    )
}